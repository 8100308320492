





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { PurchaseModule } from '@/store/modules'
import { fixFloatingValue } from '@/services/functions'

@Component
export default class PurchaseAmountCard extends Vue {
  @PurchaseModule.State purchaseAmounts
  @PurchaseModule.State currentAmountSelected
  @PurchaseModule.State promotion

  @Prop() purchaseAmountId
  @Prop({ type: Boolean, required: false, default: false}) lastSlot

  fixFloatingValue = fixFloatingValue

  descriptive = false
  purchaseAmountDetails: Nullable<PurchaseAmount> = {} as PurchaseAmount

  mounted() {
    if (this.purchaseAmountId == null) {
      this.descriptive = true
      return
    }

    this.purchaseAmountDetails = this.purchaseAmounts.find(e => e.id === this.purchaseAmountId)
  }

  @Watch('currentAmountSelected')
  onCurrentAmountSelectedIdChange(id) {
    if (id >= 7 && this.lastSlot) {
      this.purchaseAmountDetails = this.purchaseAmounts.find(e => e.id === id)
      this.descriptive = false
    } else if (id < 7 && this.lastSlot) {
      this.descriptive = true
    }
  }

  get amount() {
    if (!this.purchaseAmountDetails) return 0
  
    const { totalCurrency } = this.purchaseAmountDetails

    return Math.round(totalCurrency * (1 + (this.promotion?.value || 0)))
  }
}
